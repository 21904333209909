import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './mainComponent/header/header.component';
import { FooterComponent } from './mainComponent/footer/footer.component';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { AgmCoreModule } from '@agm/core';
import { TokenInterceptorService } from './service/token-interceptor-service/token-interceptor.service';
import { EditUserProfileComponent } from './component/user profile/edit-user-profile/edit-user-profile.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
// we are using this in edit profile for all time zone list
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import {
  OwlMomentDateTimeModule,
  OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS
} from 'ng-pick-datetime-moment';
import { AgmDirectionModule } from 'agm-direction';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { AgmOverlays } from 'agm-overlays';

import { ImageModalComponent } from './modal/image-modal/image-modal.component';
import { FilterPipe } from './pipe/list-filter-pipe/list-filter-pipe';
import { TimePipe } from './pipe/time-pipe/time.pipe';
import { FilterByKeyPipe } from './pipe/filter-by-key-pipe/filter-by-key-pipe';
import { OverviewComponent } from './component/dashboard/overview/overview.component';
// import { AngularSvgIconModule } from 'angular-svg-icon';
import { StaffListComponent } from './component/staffs/staff-list/staff-list.component';
import { AddStaffComponent } from './component/staffs/add-staff/add-staff.component';
import { CreateTripComponent } from './component/trips/create-trip/create-trip.component';
import { TripListComponent } from './component/trips/trip-list/trip-list.component';
import { TripFileComponent } from './component/import-file/trip-file/trip-file.component';
import { RolePipe } from './pipe/role-value-filter/role.pipe';
import { AccountStatusPipe } from './pipe/account-status-value-filter/account-status.pipe';
import { ToggleButtonComponent } from './utils/toggle-button.component';
import { ViewTripComponent } from './component/trips/view-trip/view-trip.component';
import { MySocketWrapper } from './service/socket-service/socket-wrapper';
import { SocketService } from './service/socket-service/socket-service.service';
import { ToastMessagesComponent } from './toast-messages/toast-messages.component';
import { ReversePipe } from './pipe/ReversePipe/reverse.pipe';
import { LogsComponent } from './component/trips-logs/logs/logs.component';
import {
  NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  SPINNER, POSITION, PB_DIRECTION
} from 'ngx-ui-loader';
import { ReportComponent } from './component/trips-report/report/report.component';
import { SidemenuComponent } from './mainComponent/sidemenu/sidemenu.component';
import { MemberReportComponent } from './component/trips-report/member-report/member-report.component';
import {TimezonePipe} from './pipe/timezone-pipe/timezone.pipe';

export const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HHmm',
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};


const ngxUiLoaderConfig: NgxUiLoaderConfig  = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 80,
  bgsType: 'ball-spin-clockwise',
  blur: 0,
  fgsColor: '#727cf5',
  fgsPosition: 'center-center',
  fgsSize: 40,
  fgsType: 'ball-spin',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40,40,40,0)',
  pbColor: '#727cf5',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignInComponent,
    ForgotPasswordComponent,
    EditUserProfileComponent,
    GooglePlaceDirective,
    ImageModalComponent,
    FilterPipe,
    TimePipe,
    RolePipe,
    AccountStatusPipe,
    FilterByKeyPipe,
    OverviewComponent,
    StaffListComponent,
    AddStaffComponent,
    CreateTripComponent,
    TripListComponent,
    TripFileComponent,
    ToggleButtonComponent,
    ViewTripComponent,
    ToastMessagesComponent,
    ReversePipe,
    LogsComponent,
    ReportComponent,
    SidemenuComponent,
    MemberReportComponent,
    TimezonePipe

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    // AngularSvgIconModule.forRoot(),

    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), // import NgxUiLoaderModule
    NgxUiLoaderHttpModule.forRoot({ exclude: [
    'https://nemtpanel.com/api/users/me'],
     showForeground: true }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA',
      libraries: ['places']
    }),
    SocketIoModule,
    AgmDirectionModule,
    TimezonePickerModule,
    BrowserAnimationsModule,

    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    AgmOverlays
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: AuthorizationGuard, useClass: AuthorizationGuard },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },

    {
      provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
      useValue: { useUtc: false }
    },
    MySocketWrapper,
    SocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
