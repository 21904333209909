import { Component, OnInit } from "@angular/core";
import { sweetAlert } from "../../../utils/swal";
import { LogsService } from "../logsService/logs-service";

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  LogsList;

  constructor(
    private logsService: LogsService
  ) { }

  ngOnInit() {
    this.getLogsList();
  }
  getLogsList() {
    this.logsService
      .getLogs()
      .subscribe(
        data => {
          this.LogsList = data.reverse();
        },
        error => {
          // sweetAlert("Error", state.errorMessage, "warning", "OK");
        }
      );
  }
}
