import { environment } from "src/environments/environment";
export class urls {
  static SERVER_URL = environment.SERVER_URL;
  static BASE_URL = environment.BASE_URL;
  static SIGNIN_URL = "auth/signin";
  static FORGOT_URL = "auth/forgot";
  static PROFILE_URL = "users/me";
  static CHANGE_PASSWORD_URL = "users/password";
  static CHANGE_PROFILE_IMAGE = "users/picture";
  static COOPERATED_DISPATCHES_URL = "cooperatedispatches";
  static DASHBOARD_LIST_URL = "facilitytrips/dashboard";
  static LOGS_LIST_URL = "facilitytrips/logs";
  static STAFF_URL = "facilitystaffs";
  static STAFF_CREATE_URL = "staffs/create";
  static CREATE_NEW_TRIP_URL = "facilitytrips";
  static GET_UNASSIGN_TRIPS_URL = "facilitytrips";
  static PARSE_TRIPS_FILE = "assign/parse";
  static GET_TRIPS_BY_DATE = "assignByDate";
  static GET_TRIPS_BY_TIMELINE = "assignByTimeline";
  static GET_TRIPS_BY_DRIVER = "assign/groupBy/Drivers";
  static GET_TRIPS_BY_BUCKETS = "assign/groupBy/buckets";
  static GET_DRIVERS_LIST = "drivers";
  static ADD_DRIVER_PROFILE = "driver/auth/signup";
  static GET_DRIVER_BY_ID = "driver/me?_id=";
  static UPDATE_DRIVER_PROFILE = "driver/update";
  static UPLOAD_DRIVER_IMAGE = "driver/picture";
}
