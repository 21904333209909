import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'src/app/commonService/message.service';

@Component({
  selector: 'app-trip-file',
  templateUrl: './trip-file.component.html',
  styleUrls: ['./trip-file.component.css']
})
export class TripFileComponent implements OnInit,OnDestroy {
  constructor(private messageService: MessageService) {}


  ngOnInit() {
    this.messageService.showFilter({
      searchFilter: true,
      searchMember: true,
      searchDate: true,
    });
  }

  ngOnDestroy(): void {
    this.messageService.hideFilter();
  }
}
