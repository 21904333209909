import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { ReportService } from '../report-service/report.service';

@Component({
  selector: 'app-member-report',
  templateUrl: './member-report.component.html',
  styleUrls: ['./member-report.component.css']
})
export class MemberReportComponent implements OnInit {


  searchForm: FormGroup;
  submitted = false;
  memberReportsData;
  selectedFacility = null;
  startDate = null;
  endDate = null;
  totalJobFee = 0;
  totalJobs = 0;
  completedJobs = 0;
  cancelledJobs = 0;
  memberReportsDataState: Observable<any>
  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.selectedFacility = this.authService.getUserData();

    this.searchForm = this.fb.group({
      user: [this.authService.getUserData()._id, Validators.required],
      reportType: ['member', Validators.required],
      providerStatus: [''],
      startDate: [null],
      endDate: [null],
      dateRange: [''],
      displayName: ['', Validators.required]
    })
  }

  onDateChange(event) {
    if (event) {
      this.searchForm.patchValue({
        startDate: event[0].toISOString(),
        endDate: event[1].toISOString()
      });
    }
  }

  get form() {
    return this.searchForm.controls;
  }

  calculateJobFee(trips) {
    this.totalJobFee = 0;
    trips.forEach(element => {
      this.totalJobFee += element.jobFee;
    });
  }

  downloadFacilityReport() {
    if (this.memberReportsData.length > 0) {
      console.log(this.selectedFacility)
      
      this.reportService
      .downloadMemberReport({reportData: this.memberReportsData, facility: this.selectedFacility, startDate: this.startDate, endDate: this.endDate, jobFee: this.totalJobFee, reportType: 'Facility Trips Report', totalJobs: this.totalJobs, completedJobs: this.completedJobs, cancelledJobs: this.cancelledJobs})
      .subscribe(
        data => {
          if (data) {
            this.downloadReport(data);
          }   
        },
        error => {
          sweetAlert('Error', error, 'warning', 'OK'); 
        }
      );
    }
  }


  onSubmit() {
    this.memberReportsData = undefined;
    console.log(this.searchForm)
    this.submitted = true;
    if (this.searchForm.invalid) {
      return
    }

    this.reportService
      .getFacilityReportsList(this.searchForm.value)
      .subscribe(
        data => {
          if (data && data['length'] > 0) {
            this.memberReportsData = data[0].jobs;
            this.totalJobs = data[0].totalJobs;
            this.completedJobs = data[0].completedJobs;
            this.cancelledJobs = data[0].cancelledJobs;
            this.startDate = this.searchForm.value.startDate;
            this.endDate = this.searchForm.value.endDate;
            this.calculateJobFee(this.memberReportsData);
          }
        },
        error => {
          sweetAlert('Error', error, 'warning', 'OK');
        }
      );
  }

  downloadReport(data) {

    console.log('helo')
    // console.log(url, fileName);
    const body = <HTMLDivElement>document.body;
    const linkSource =  data['pdfFile'];
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = data['fileName'];
    body.appendChild(downloadLink);
    console.log(downloadLink)
    console.log(data['pdfFile'])
    console.log(data['fileName'])
    console.log(downloadLink.click())
    // downloadLink.click();
    // downloadLink.dispatchEvent(new MouseEvent('click'));
    var event = document.createEvent('Event');
    event.initEvent('click', true, true);
    downloadLink.dispatchEvent(event);
    // (window.URL || window.webkitURL).revokeObjectURL(downloadLink.href);

  }
}
