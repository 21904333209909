import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class LogsService {
  constructor(private http: HttpClient) {
  }
  getLogs(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.LOGS_LIST_URL)
      .pipe(
        catchError(errorHandl)
      );
  }
}
