import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicMenuService } from "./dynamic-menu-service/dynamic-menu.service";
import { Observable } from "rxjs";
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
declare var $;
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
  userPlatform  ;
  groupedMenu = [
    {
      'Main': ['dashboard', 'trips']
    },
    {
      'Business': ['staffs', 'logs', 'reports']
    }
  ]
  menu = null;

  constructor(
    private dynamicMenuService: DynamicMenuService, 
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user && !this.menu) {
          this.userPlatform = user.platform;
          this.menu = this.dynamicMenuService.getMenu(user.roles[0]);
          $.getScript('../assets/js/template.js');
        }
      });

    // this.getState
    // .pipe(takeUntil(componentDestroyed(this)))
    // .subscribe((state) => {
    //   console.log('SideMenuState', state);
    //   // console.log('STATE MENU', state);
    //   if (state.user != null && this.menu == null) {
    //     this.userPlatform = state.user.platform;
    //     this.menu = this.dynamicMenuService.getMenu(state.user.roles[0]);
    //     // console.log(state.user.roles[0])
    //     // console.log(this.menu)
    //     $.getScript('../assets/js/template.js');
    //     console.log('menu_',this.menu);
    //   }
    // });

  }

  sortMethod(a, b) {
    return a.value.position > b.value.position ? 1 : -1;
  }

}
