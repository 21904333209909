import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { urls } from "../../../utils/url-utils";
import { errorHandl } from "../../../utils/network-utils";
import { Observable, BehaviorSubject,  } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TripService {
  private assignTripSource: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  getAssignList(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append("isShowAllTrips", endPoint.isShowAllTrips);
    params = params.append("status", endPoint.status);
    if (endPoint.date) {
      params = params.append("scheduleTime", endPoint.date);
    }

    return this.http
      .get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, { params })
      .pipe(catchError(errorHandl));
  }

  createNewTrip(tripData, tripId?): Observable<any> {
    const timeKeys = [
      "schedulePickupHour",
      "schedulePickupMinutes",
      "appointmentPickupHour",
      "appointmentPickupMinutes",
      "scheduledTime",
    ];
    delete tripData["companyType"]; // not require right now
    timeKeys.forEach((element) => {
      delete tripData[element];
    });
    tripData.tripLegs = tripData.tripLegs.splice(1, tripData.tripLegs.length);
    if (tripData.tripLegs.length === 0) {
      delete tripData["tripLegs"];
    } else if (tripData.tripLegs.length > 0 && !tripData.isMedical) {
      for (let i = 0; i < tripData.tripLegs.length; i++) {
        delete tripData.tripLegs[i].tripId;
      }
    }
    console.log(tripData);
    if (tripId) {
      return this.http
        .put<any>(
          urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + "/" + tripId,
          tripData
        )
        .pipe(catchError(errorHandl));
    } else {
      delete tripData._id;
      return this.http
        .post<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL, tripData)
        .pipe(catchError(errorHandl));
    }
  }

  getUnassignedTripDetailById(unassignedTripId): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + "/" + unassignedTripId
      )
      .pipe(catchError(errorHandl));
  }

  changeAssignTripSource(data) {
    this.assignTripSource.next(data);
  }

  getCurrentAssignTripSource() : Observable<any> {
    return this.assignTripSource.asObservable();
  }
}
