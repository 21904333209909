import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { AuthService } from "src/app/service/auth-service/auth.service";
import * as moment from "moment-timezone";
import { getBrokersList } from "src/app/utils/utils.common";
import { sweetAlert } from "src/app/utils/swal";
import { Observable } from "rxjs";
import { dummyData } from "src/app/component/trips/dummy-trip-data/dummy-trip"
import { Router, ActivatedRoute } from "@angular/router";
import { TripService } from "../trip-service/trip.service";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: "app-create-trip",
  templateUrl: "./create-trip.component.html",
  styleUrls: ["./create-trip.component.css"],
})
export class CreateTripComponent implements OnInit, OnDestroy {
  legId = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  btnName = "Create";
  timeZone;
  legsForm: FormArray;
  noOfLegs = 0;
  todayDate;
  googleMilage = null;
  newTripForm: FormGroup;
  submitted = false;
  getAssignTripsState: Observable<any>;
  assignedTrips;
  unassignedTripId;
  unassignedTripDetailById = null;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tripService: TripService
  ) {
    this.timeZone = this.authService.getUserData()
      ? this.authService.getUserData().timeZone
      : "America/New_York";
  }

  ngOnInit() {
    console.log(this.timeZone);
    this.todayDate = moment().tz(this.timeZone).format();
    console.log(moment());
    console.log(this.todayDate);


    // this.getAssignTripsState.subscribe((state) => {
    //   if (
    //     state.success &&
    //     state.type === AssignTripsActionTypes.CREATE_NEW_TRIP
    //   ) {
    //     this.router.navigateByUrl("assignstrips/unassigned");
    //   } else if (
    //     state.success &&
    //     state.type === AssignTripsActionTypes.UPDATE_NEW_TRIP
    //   ) {
    //     sweetAlert("Success", "Trip Updated Succesfully", "success", "OK").then(
    //       () => {
    //         this.router.navigateByUrl("trips/list");
    //       }
    //     );
    //   } else if (state.errorMessage) {
    //     sweetAlert("Error", state.errorMessage, "error", "OK");
    //   }
    // });

    this.newTripForm = this.fb.group({
      _id: [null],
      appointmentTime: [this.todayDate, Validators.required],
      companyNote: [""],
      jobDestinationAddress: ["", Validators.required],
      jobDestinationLatitude: [0, Validators.required],
      jobDestinationLongitude: [0, Validators.required],
      jobOriginAddress: ["", Validators.required],
      jobOriginLatitude: [0, Validators.required],
      jobOriginLongitude: [0, Validators.required],
      jobPassengerNumber: [1, Validators.required],
      jobType: ["assigned", Validators.required],
      priorityClient: this.fb.group({
        displayName: ["", Validators.required],
        contactNumber: ["", Validators.required],
        cellPhone: [""],
        clientId: ["", Validators.required]
      }),
      dob: ["", Validators.required],
      scheduleTime: [this.todayDate, Validators.required],
      isMedical: [true],
      iSschUrgentCare: [false],
      isUrgentCare: [true],
      isWheelChair: [false],
      isCarSeat: [false],
      isLongDistance: [false],
      isOxygen: [false],
      memberId: [""],
      // tripId: [{ value: '', disabled: true }],
      tripRequirement: ["AMB"],
      schedulePickupHour: ["", Validators.required],
      schedulePickupMinutes: ["", Validators.required],
      appointmentPickupHour: ["", Validators.required],
      appointmentPickupMinutes: ["", Validators.required],
      scheduledTime: [this.todayDate, Validators.required],
      milage: [null],
      tripLegs: this.fb.array([this.createLeg(0)]),

      // new things
      isWillCall: [false],
      additionalOptions: ["singleLeg"],
      additionalMember: [false],
      additionalPersonName: [""],
      additionalPersonGender: [""],

      prefferedProvider: [{ value:false, disabled: true }],
      companyType: [{ value:"", disabled: true }],

      tripReason: ["Doctor Appointment"],
      doctorName: ["", Validators.required],
      doctorContactNumber: ["", Validators.required],

      payorType: ["Medicaid"],
      medicalId: ["", Validators.required],
      memberNotes: [""],
      changeReason: [""],
      driverInstructions: [""],
      memberHomeZipCode: ["", Validators.required],
    });

    // this.checkEditTrip();
    this.formValueChangeListener();

    if (
      this.activatedRoute.snapshot.url.length === 2 &&
      this.activatedRoute.snapshot.url[1].path === "edit"
    ) {
      this.tripService
        .getCurrentAssignTripSource()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((data) => {
          console.log(data);
          this.newTripForm.patchValue({
            ...data,
          });

          if (data && data.additionalOptions === "multiLeg" && data.tripLegs) {
            this.clearTripLegs();
            this.patchLegData(data.tripLegs);
          }else{
            // if legs data length 0
            this.newTripForm.patchValue({
              additionalOptions: "singleLeg",
            });
          }

          console.log(this.newTripForm.value);
          if (data && data._id) {
            this.unassignedTripDetailById = data;
            this.setPrefilledTrip(this.unassignedTripDetailById);
            this.formSelected();
            this.btnName = "Update";
          }
        });
    }
  }
  formValueChangeListener() {
    // additionalMember
    this.newTripForm.controls.additionalMember.valueChanges.subscribe(
      (checked) => {
        if (checked) {
          this.newTripForm.controls.additionalPersonName.setValidators([Validators.required]);
          this.newTripForm.controls.additionalPersonGender.setValidators([Validators.required]);
        } else {
          this.newTripForm.patchValue({
            additionalPersonName: "",
            additionalPersonGender: "",
          });
          this.newTripForm.controls.additionalPersonName.clearValidators();
          this.newTripForm.controls.additionalPersonName.setErrors(null);
          this.newTripForm.controls.additionalPersonGender.clearValidators();
          this.newTripForm.controls.additionalPersonGender.setErrors(null);
        }
        this.newTripForm.updateValueAndValidity();
      }
    );
    // prefferedProvider
    this.newTripForm.controls.prefferedProvider.valueChanges.subscribe(
      (checked) => {
        if (checked) {
          // this.newTripForm.controls.companyType.setValidators([Validators.required]);
        } else {
          this.newTripForm.patchValue({
            companyType: "",
          });
          this.newTripForm.controls.companyType.clearValidators();
          this.newTripForm.controls.companyType.setErrors(null);
        }
        this.newTripForm.updateValueAndValidity();
      }
    );
  }

  checkEditTrip() {
    if (
      this.activatedRoute.snapshot.url.length === 3 &&
      this.activatedRoute.snapshot.url[2].path === "edit"
    ) {
      this.unassignedTripId = this.activatedRoute.snapshot.url[1].path;

      this.tripService
        .getUnassignedTripDetailById(this.unassignedTripId)
        .subscribe(
          data => {
            this.unassignedTripDetailById = data;
            this.timeZone = this.unassignedTripDetailById.facility.timeZone;
            this.setPrefilledTrip(this.unassignedTripDetailById);
            this.formSelected();
            this.btnName = "Update";
          },
          error => {
            sweetAlert("Error", error, "error", "OK"); 
          }
        );
    }
  }

  setPrefilledTrip(trip) {

    trip.scheduleTime = moment(trip.scheduleTime).tz(this.timeZone).format();
    trip.appointmentTime = moment(trip.appointmentTime).tz(this.timeZone).format();

    this.newTripForm.patchValue({
      ...trip,
      scheduledTime: moment(trip.scheduleTime, 'YYYY-MM-DD hh:mm').tz(this.timeZone).format(),
    });
    // console.log(trip.appointmentTime);
    // console.log(trip);
    // console.log(moment.utc(trip.appointmentTime).tz(this.timeZone).format());
    if (trip.appointmentTime) {
      // getting utc time from backend, it will convert the utc time to company timezone
      // trip.appointmentTime = moment(trip.appointmentTime)

        // .format();
        // .utc(trip.appointmentTime)
        // .tz(this.timeZone)
      // tslint:disable-next-line: radix
      if (
        parseInt(trip.appointmentTime.split("T")[1].substr(3, 2)) % 10 !==
        0
      ) {
        // tslint:disable-next-line: radix
        const min =
          parseInt(trip.appointmentTime.split("T")[1].substr(3, 2)) +
          // tslint:disable-next-line: radix
          (10 -
            (parseInt(trip.appointmentTime.split("T")[1].substr(3, 2)) % 10));
        this.newTripForm.patchValue({
          appointmentPickupHour: trip.appointmentTime
            .split("T")[1]
            .substr(0, 2),
          appointmentPickupMinutes: min.toString(),
        });
      } else {
        this.newTripForm.patchValue({
          appointmentPickupHour: trip.appointmentTime
            .split("T")[1]
            .substr(0, 2),
          appointmentPickupMinutes: trip.appointmentTime
            .split("T")[1]
            .substr(3, 2),
        });
      }
    }
    if (trip.scheduleTime) {
      // getting utc time from backend, it will convert the utc time to company timezone
      //  trip.scheduleTime = moment(trip.scheduleTime)

      // .format();
        // .utc(trip.scheduleTime)
        // .tz(this.timeZone)
        // .format();
      if (parseInt(trip.scheduleTime.split("T")[1].substr(3, 2)) % 10 !== 0) {
        let min =
          parseInt(trip.scheduleTime.split("T")[1].substr(3, 2)) +
          (10 - (parseInt(trip.scheduleTime.split("T")[1].substr(3, 2)) % 10));
        this.newTripForm.patchValue({
          schedulePickupHour: trip.scheduleTime.split("T")[1].substr(0, 2),
          schedulePickupMinutes: min.toString(),
        });
      } else {
        this.newTripForm.patchValue({
          schedulePickupHour: trip.scheduleTime.split("T")[1].substr(0, 2),
          schedulePickupMinutes: trip.scheduleTime.split("T")[1].substr(3, 2),
        });
      }
    }
  }

  formSelected() {
    this.assignedTrips = null;
    this.newTripForm.patchValue({
      companyType: this.unassignedTripDetailById
        ? this.unassignedTripDetailById.companyType
        : "",
      tripRequirement:
        this.unassignedTripDetailById && this.unassignedTripDetailById.isMedical
          ? this.unassignedTripDetailById.tripRequirement
          : "AMB",
      isMedical: true,
    });
  }

  get form() {
    return this.newTripForm.controls;
  }

  setTripLegTime(index?) {
    console.log(index);
    if (index) {
      if (index === 1) {
        if (this.newTripForm.value.appointmentPickupHour === "23") {
          this.legsForm.at(index).patchValue({
            schedulePickupHour: this.newTripForm.value.appointmentPickupHour,
            schedulePickupMinutes: this.newTripForm.value
              .appointmentPickupMinutes,
          });
        } else {
          if (this.newTripForm.value.appointmentPickupHour) {
            // tslint:disable-next-line: radix
            let schedulePickTime =
              parseInt(this.newTripForm.value.appointmentPickupHour) + 1;
            let updatedScheduePickTime = schedulePickTime.toString();
            if (schedulePickTime.toString().length === 1) {
              updatedScheduePickTime = "0" + updatedScheduePickTime;
            }
            this.legsForm.at(index).patchValue({
              schedulePickupHour: updatedScheduePickTime,
              schedulePickupMinutes: this.newTripForm.value
                .appointmentPickupMinutes,
            });
          }
        }
      } else {
        if (
          this.newTripForm.value.tripLegs[index - 1].schedulePickupHour === "23"
        ) {
          this.legsForm.at(index).patchValue({
            schedulePickupHour: this.newTripForm.value.tripLegs[index - 1]
              .schedulePickupHour,
            schedulePickupMinutes: this.newTripForm.value.tripLegs[index - 1]
              .schedulePickupMinutes,
          });
        } else {
          if (this.newTripForm.value.tripLegs[index - 1].schedulePickupHour) {
            // tslint:disable-next-line: radix
            let schedulePickTime =
              parseInt(
                this.newTripForm.value.tripLegs[index - 1].schedulePickupHour
              ) + 1;
            let updatedScheduePickTime = schedulePickTime.toString();
            if (schedulePickTime.toString().length === 1) {
              updatedScheduePickTime = "0" + updatedScheduePickTime;
            }
            this.legsForm.at(index).patchValue({
              schedulePickupHour: updatedScheduePickTime,
              schedulePickupMinutes: this.newTripForm.value.tripLegs[index - 1]
                .schedulePickupMinutes,
            });
          }
        }
      }
    } else {
      console.log(this.legsForm);
      if (this.legsForm) {
        this.legsForm.at(0).patchValue({
          schedulePickupHour: this.newTripForm.value.appointmentPickupHour,
          schedulePickupMinutes: this.newTripForm.value
            .appointmentPickupMinutes,
        });
      }
      for (let k = 1; k < this.newTripForm.value.tripLegs.length; k++) {
        // tslint:disable-next-line: radix
        const time = parseInt(this.newTripForm.value.appointmentPickupHour) + k;
        if (time >= 23) {
          this.legsForm.at(k).patchValue({
            schedulePickupHour: "23",
            schedulePickupMinutes: this.newTripForm.value
              .appointmentPickupMinutes,
          });
        } else {
          let updatedTime = time.toString();
          if (time.toString().length === 1) {
            updatedTime = "0" + updatedTime.toString();
          }
          this.legsForm.at(k).patchValue({
            schedulePickupHour: updatedTime,
            schedulePickupMinutes: this.newTripForm.value
              .appointmentPickupMinutes,
          });
        }
      }

      console.log(this.newTripForm.value);


      // }
    }
  }
  createLeg(index): FormGroup {
    return this.fb.group({
      scheduleTime: ["", index > 0 ? Validators.required : null],
      schedulePickupHour: ["", index > 0 ? Validators.required : null],
      schedulePickupMinutes: ["", index > 0 ? Validators.required : null],
      jobOriginAddress: ["", index > 0 ? Validators.required : null],
      jobOriginLatitude: ["", index > 0 ? Validators.required : null],
      jobOriginLongitude: ["", index > 0 ? Validators.required : null],
      jobDestinationAddress: ["", index > 0 ? Validators.required : null],
      jobDestinationLatitude: ["", index > 0 ? Validators.required : null],
      jobDestinationLongitude: ["", index > 0 ? Validators.required : null],
      // tripId: [{ value: '', disabled: true }],
       dob: [""],
    });
  }

  addLeg(noOfLegs) {
    noOfLegs++;
    this.legsForm.push(this.createLeg(noOfLegs));
    if (noOfLegs === 1) {
      this.legsForm.at(noOfLegs).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,

      });
      this.legsForm.at(0).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,


      });
    } else if (noOfLegs > 1) {
      this.legsForm.at(noOfLegs - 1).patchValue({
        jobDestinationAddress: "",
        jobDestinationLatitude: "",
        jobDestinationLongitude: "",
      });
      this.legsForm.at(noOfLegs).patchValue({
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,


      });
    }

    this.setTripLegTime(noOfLegs);
    if (this.newTripForm.value.tripId) {
      this.legsForm.at(noOfLegs).patchValue({
        tripId:
          this.newTripForm.value.tripId.split("-")[0] +
          "-Leg(" +
          this.legId[noOfLegs] +
          ")",
      });
    }
  }

  removeLeg(index) {
    if (index === 0 && this.newTripForm.value.tripLegs.length > 2) {
      this.legsForm.removeAt(index + 1);
      this.legsForm.at(index + 1).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
      });
    } else if (this.newTripForm.value.tripLegs.length > index + 1) {
      this.legsForm.at(index).patchValue({
        jobDestinationAddress: this.newTripForm.value.tripLegs[index + 1]
          .jobDestinationAddress,
        jobDestinationLatitude: this.newTripForm.value.tripLegs[index + 1]
          .jobDestinationLatitude,
        jobDestinationLongitude: this.newTripForm.value.tripLegs[index + 1]
          .jobDestinationLongitude,
      });
      this.legsForm.removeAt(index + 1);
    } else {
      this.legsForm.removeAt(index + 1);
    }
    // this.bindReturnTripId();
  }
  get legsElement() {
    this.legsForm = this.newTripForm.controls.tripLegs as FormArray;
    return this.legsForm;
  }


  bindTripId() {
    // if (!this.newTripForm.value.tripId.toUpperCase().includes("-LEG(A)")) {
    //   this.newTripForm.patchValue({
    //     tripId: this.newTripForm.value.tripId + "-Leg(A)",
    //   });
    // }
    // this.bindReturnTripId();x
  }

  bindReturnTripId() {
    // if (this.legsForm && this.legsForm.length > 0) {
    //   for (let i = 1; i < this.legsForm.length; i++) {
    //     this.legsForm.at(i).patchValue({
    //       tripId:
    //         this.newTripForm.value.tripId.split("-")[0] +
    //         "-Leg(" +
    //         this.legId[i] +
    //         ")",
    //     });
    //   }
    // }
  }

  setMinsAndHours() {
    if (this.legsForm && this.legsForm.length > 0) {
      for (let i = 0; i < this.legsForm.length; i++) {
        this.legsForm.at(i).patchValue({
          scheduleTime: this.newTripForm.value.scheduleTime,
        });
      }
      for (let i = 0; i < this.legsForm.length; i++) {
        let scheduleTime = this.newTripForm.value.tripLegs[
          i
        ].scheduleTime;


        // scheduleTime = moment
        //   .tz(
        //     scheduleTime +
        //       " " +
        //       this.newTripForm.value.tripLegs[i].schedulePickupHour +
        //       ":" +
        //       this.newTripForm.value.tripLegs[i].schedulePickupMinutes +
        //       ":00",
        //     this.timeZone
        //   )
        //   .toISOString();
        if (scheduleTime !== null) {
          this.legsForm.at(i).patchValue({
            scheduleTime:  moment(scheduleTime).tz(this.timeZone).set("hour",  this.newTripForm.value.tripLegs[i].schedulePickupHour).set("minute", this.newTripForm.value.tripLegs[i].schedulePickupMinutes).format()
          });
        }
      }
    }


    // Date Leg A

    let ScheduleTime = this.newTripForm.value.scheduleTime;
    // ScheduleTime = moment
    //   .tz(
    //     ScheduleTime +
    //       " " +
    //       this.newTripForm.value.schedulePickupHour +
    //       ":" +
    //       this.newTripForm.value.schedulePickupMinutes +
    //       ":00",
    //     this.timeZone
    //   )
    //   .toISOString();
    let AppointmentTime = this.newTripForm.value.appointmentTime;
    // AppointmentTime = moment
    //   .tz(
    //     AppointmentTime +
    //       " " +
    //       this.newTripForm.value.appointmentPickupHour +
    //       ":" +
    //       this.newTripForm.value.appointmentPickupMinutes +
    //       ":00",
    //     this.timeZone
    //   )
    //   .toISOString();
    console.log(ScheduleTime, AppointmentTime);
    console.log(this.newTripForm.value.schedulePickupHour, this.newTripForm.value.schedulePickupMinutes);
    console.log(this.newTripForm.value.appointmentPickupHour, this.newTripForm.value.appointmentPickupMinutes);

    if (ScheduleTime !== null && AppointmentTime !== null) {
      this.newTripForm.patchValue({
        scheduleTime: moment(ScheduleTime).tz(this.timeZone).set("hour", this.newTripForm.value.schedulePickupHour).set("minute", this.newTripForm.value.schedulePickupMinutes).format(),
        appointmentTime: moment(AppointmentTime).tz(this.timeZone).set("hour", this.newTripForm.value.appointmentPickupHour).set("minute", this.newTripForm.value.appointmentPickupMinutes).format(),
      });
    }
    console.log(this.newTripForm.value.scheduleTime, this.newTripForm.value.appointmentTime);

  }

  checkTripIdUnique() {
    const tempTripIdArray = [];
    tempTripIdArray.push(this.newTripForm.value.tripId);

    for (let i = 1; i < this.legsForm.length; i++) {
      tempTripIdArray.push(this.newTripForm.value.tripLegs[i].tripId);
    }
    if ([...new Set(tempTripIdArray)].length === tempTripIdArray.length) {
      return true;
    }
    return false;
  }

  setDob() {

    if (this.legsForm && this.legsForm.length > 0) {
      for (let i = 1; i < this.legsForm.length; i++) {
        this.legsForm.at(i).patchValue({
          dob: this.newTripForm.value.dob
        });
      }
    }


  }

  onScheduleDateChange(event) {
    console.log(event);
    const date = moment.utc(event).local().format();
    console.log(date);
    if (event) {
      this.newTripForm.patchValue({
        scheduleTime: date,
        appointmentTime: date,
      });
    }
  }

  onScheduleTimeChange() {
    if (
      this.newTripForm.value.schedulePickupHour &&
      this.newTripForm.value.schedulePickupMinutes
    ) {
      if (this.newTripForm.value.schedulePickupHour === "23") {
        this.newTripForm.patchValue({
          appointmentPickupHour: this.newTripForm.value.schedulePickupHour,
          appointmentPickupMinutes: this.newTripForm.value
            .schedulePickupMinutes,
        });
      } else {
        // tslint:disable-next-line: radix
        let aptPickHour = (
          parseInt(this.newTripForm.value.schedulePickupHour) + 1
        ).toString();
        if (aptPickHour.length === 1) {
          aptPickHour = "0" + aptPickHour;
        }
        this.newTripForm.patchValue({
          appointmentPickupHour: aptPickHour,
          appointmentPickupMinutes: this.newTripForm.value
            .schedulePickupMinutes,
        });
      }
      this.setTripLegTime();
    }
  }

  onAppointmentTimeChange() {

    if (
      this.newTripForm.value.appointmentPickupHour &&
      this.newTripForm.value.appointmentPickupMinutes
    ) {
      this.setTripLegTime();
    }
  }

  getPickUpAddress(place, index) {
    this.legsForm.at(index).patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginAddressLatitude: place.geometry.location.lat(),
      jobOriginAddressLongitude: place.geometry.location.lng(),
    });
  }

  getDropOffAddress(place, index) {
    this.legsForm.at(index).patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng(),
    });
    if (this.legsForm.length > index + 1) {
      this.legsForm.at(index + 1).patchValue({
        jobOriginAddress: place.formatted_address,
        jobOriginLatitude: place.geometry.location.lat(),
        jobOriginLongitude: place.geometry.location.lng(),
      });
    }
  }
  getOriginAddress(place) {
    this.newTripForm.patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng(),
    });
    if (this.legsForm) {
      this.legsForm.at(0).patchValue({
        jobOriginAddress: place.formatted_address,
        jobOriginLatitude: place.geometry.location.lat(),
        jobOriginLongitude: place.geometry.location.lng(),
      });
    }
  }

  getDestinationAddress(place) {
    this.newTripForm.patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng(),
    });
    if (this.legsForm) {
      this.legsForm.at(0).patchValue({
        jobDestinationAddress: place.formatted_address,
        jobDestinationLatitude: place.geometry.location.lat(),
        jobDestinationLongitude: place.geometry.location.lng(),
      });
    }
  }

  onTripLegTimeChange(index) {
    console.log(index);
    for (let i = index; i < this.newTripForm.value.tripLegs.length; i++) {
      if (this.newTripForm.value.tripLegs[i - 1].schedulePickupHour === "23") {
        this.legsForm.at(i).patchValue({
          schedulePickupHour: this.newTripForm.value.tripLegs[i - 1]
            .schedulePickupHour,
          schedulePickupMinutes: this.newTripForm.value.tripLegs[i - 1]
            .schedulePickupMinutes,
        });
      } else {
        if (this.newTripForm.value.tripLegs[i - 1].schedulePickupHour) {
          let schedultPickTime =
            parseInt(
              this.newTripForm.value.tripLegs[i - 1].schedulePickupHour
            ) + 1;
          let updatedScheduePickTime = schedultPickTime.toString();
          if (schedultPickTime.toString().length === 1) {
            updatedScheduePickTime = "0" + updatedScheduePickTime;
          }
          this.legsForm.at(i).patchValue({
            schedulePickupHour: updatedScheduePickTime,
            schedulePickupMinutes: this.newTripForm.value.tripLegs[index - 1]
              .schedulePickupMinutes,
          });
        }
      }
    }
  }
  getMiles() {
    // this.setUpSocketListener();
    // let data = {
    //   origin: this.newTripForm.value.jobOriginLatitude + ',' + this.newTripForm.value.jobOriginLongitude,
    //   destination: this.newTripForm.value.jobDestinationLatitude + ',' + this.newTripForm.value.jobDestinationLongitude
    // };
    // this.socketService.emit(Events.GET_MILES_CALL, data);
  }

  onNewTripSubmit() {
    console.log(this.newTripForm);

    this.submitted = true;
    // check trip ids
    // if (this.legsForm && this.legsForm.length > 1) {
    //   const result = this.checkTripIdUnique();
    //   if (!result) {
    //     sweetAlert("Error", "Duplicate Trip Id Found", "error", "OK");
    //     return;
    //   }
    // }


    this.setDob();
    this.setMinsAndHours();
    if (this.newTripForm.invalid) {
      return;
    }

    // console.log(this.legsForm.value);
    if (!(this.unassignedTripDetailById && this.unassignedTripDetailById._id) &&
      this.newTripForm.controls.additionalOptions.value === "multiLeg" &&
      this.legsForm.length <= 1
    ) {
      // add at least one leg
      return;
    }

    console.log(this.newTripForm.value);
    // if (this.btnName === "Update") {
    //   this.store.dispatch(
    //     new UpdateNewTrip(this.newTripForm.value, this.unassignedTripId)
    //   );
    // } else {
    //   this.store.dispatch(new CreateNewTrip(this.newTripForm.value));
    // }
    this.tripService.changeAssignTripSource(this.newTripForm.value);
    this.router.navigateByUrl("trips/view");
  }
  getLegsLength() {
    return this.legsForm.length;
  }

  patchAdditionalOptions(type) {
    this.newTripForm.patchValue({
      additionalOptions: type,
    });

    // clear trips leg onbehal of single trip button
    if (type === "singleLeg") {
      this.clearTripLegs();
    }
  }

  getAdditionalOptions(type) {
    return type === this.newTripForm.controls.additionalOptions.value;
  }

  getTripRequirement(type) {
    return type === this.newTripForm.controls.tripRequirement.value;
  }

  addTripRequirement(type) {
    this.newTripForm.patchValue({
      tripRequirement: type,
    });
  }

  patchBooleanValues(type) {
    console.log(type);
    this.newTripForm.patchValue({
      [type]: !this.newTripForm.controls[type].value,
    });
  }
  getPatchBooleanValues(type) {
    return this.newTripForm.controls[type].value;
  }

  getAllBrokersList() {
    return getBrokersList;
  }

  ngOnDestroy(): void {}

  patchLegData(dataArray) {
// auto selected field
      const linesFormArray = this.newTripForm.get("tripLegs") as FormArray;
      linesFormArray.removeAt(0);

    for (let item = 0; item < dataArray.length; item++) {

      let myLegForm = this.fb.group({
        scheduleTime: [dataArray[item].scheduleTime],
        schedulePickupHour: [dataArray[item].schedulePickupHour],
        schedulePickupMinutes: [dataArray[item].schedulePickupMinutes],
        jobOriginAddress: [dataArray[item].jobOriginAddress],
        jobOriginLatitude: [dataArray[item].jobOriginLatitude],
        jobOriginLongitude: [dataArray[item].jobOriginLongitude],
        jobDestinationAddress: [dataArray[item].jobDestinationAddress],
        jobDestinationLatitude: [dataArray[item].jobDestinationLatitude],
        jobDestinationLongitude: [dataArray[item].jobDestinationLongitude],
        // tripId: [dataArray[item].tripId],
      });
      const linesFormArray = this.newTripForm.get("tripLegs") as FormArray;
      // myLegForm.patchValue(dataArray[item]);
      linesFormArray.push(myLegForm);
    }
  }


  clearTripLegs() {
    for (let i = this.newTripForm.value.tripLegs.length; i > 0; i--) {
      const linesFormArray = this.newTripForm.get("tripLegs") as FormArray;
      linesFormArray.removeAt(i);
    }
  }

  prefillTripForm() {
  
    this.newTripForm.patchValue({...dummyData})
  }

  resetTripForm() {
    this.newTripForm.reset();
  }
}
