import { Component, OnInit, OnDestroy } from "@angular/core";
import { TripService } from "../trip-service/trip.service";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment-timezone";
import { Router, ActivatedRoute } from "@angular/router";
import { sweetAlert } from "src/app/utils/swal";
import { Observable } from "rxjs";
import { AuthService } from 'src/app/service/auth-service/auth.service';

@Component({
  selector: "app-view-trip",
  templateUrl: "./view-trip.component.html",
  styleUrls: ["./view-trip.component.css"],
})
export class ViewTripComponent implements OnInit, OnDestroy {
  assignTrip;
  getAssignTripsState: Observable<any>;
  isShowPublish = false;
  legArray = [];
  isEdit;
  origin = { lat: 0.0, lng: 0.0 };
  destination = { lat: 0.0, lng: 0.0 };
  timeZone;

  constructor(
    private tripService: TripService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.timeZone = this.authService.getUserData()
    ? this.authService.getUserData().timeZone
    : "America/New_York";
  }

  ngOnInit() {
    this.tripService
      .getCurrentAssignTripSource()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((data) => {
        console.log(data);
        this.assignTrip = data;
        if (this.assignTrip) {
          this.setupMap();

          if (
            this.assignTrip.providerStatus &&
            this.assignTrip.providerStatus === "unassigned"
          ) {
            // in unassigned case
            this.isEdit = true;
          } else if (!this.assignTrip.providerStatus) {
            // in normal create trip case
            this.isEdit = true;
          } else {
            this.isEdit = false;
          }
        }
      });

    this.checkEditTrip();
  }

  checkEditTrip() {
    console.log(this.activatedRoute.snapshot.url);
    if (
      this.activatedRoute.snapshot.url.length === 3 &&
      this.activatedRoute.snapshot.url[1].path === "view"
    ) {
      this.isShowPublish = false;

      this.tripService
      .getUnassignedTripDetailById(this.activatedRoute.snapshot.url[2].path)
      .subscribe(
        data => {
          console.log("calling");
          this.tripService.changeAssignTripSource(data);
        },
        error => {
          sweetAlert("Error", error, "error", "OK");
        }
      );
    } else {
      this.isShowPublish = true;
    }
  }

  setupMap() {
    console.log(this.assignTrip);
    if (this.assignTrip) {
      this.origin = {
        lat: this.assignTrip.jobOriginLatitude,
        lng: this.assignTrip.jobOriginLongitude,
      };
      this.destination = {
        lat: this.assignTrip.jobDestinationLatitude,
        lng: this.assignTrip.jobDestinationLongitude,
      };
    }
  }

  onPublish() {
    if (!this.assignTrip) {
      return;
    }

    this.tripService.createNewTrip(this.assignTrip, this.assignTrip._id).subscribe(
      data => {
        if (this.assignTrip._id) {
          this.tripService.changeAssignTripSource(null);
          sweetAlert(
            "Success",
            "Trip Updated Succesfully",
            "success",
            "OK"
          ).then(() => {
            this.router.navigateByUrl("trips/outstanding");
          });
        } else {
          this.tripService.changeAssignTripSource(null);
          this.router.navigateByUrl("trips/outstanding");         
        }
      },
      error => {
        sweetAlert("Error", error, "error", "OK");        
      }
    );
  }

  getTripLegData() {
    if (this.assignTrip && this.assignTrip.tripLegs) {
      this.legArray = JSON.parse(JSON.stringify(this.assignTrip.tripLegs));
      this.legArray.splice(0, 1);
    } else {
      this.legArray = [];
    }
    // console.log(this.legArray);
    return this.legArray;
  }



  setTimeZone() {

// Leg A appointmentTime
    this.assignTrip.appointmentTime = moment
      .tz(
        this.assignTrip.appointmentTime,
        this.timeZone
      ).toISOString();
// Leg A scheduleTime
      this.assignTrip.scheduleTime = moment
      .tz(
        this.assignTrip.scheduleTime,
        this.timeZone
      ).toISOString();

    // other Legs scheduleTime
      if (this.assignTrip.tripLegs && this.assignTrip.tripLegs.length > 0) {
        for (let i = 0; i < this.assignTrip.tripLegs.length; i++) {
          this.assignTrip.tripLegs[i].scheduleTime =  moment
          .tz(
            this.assignTrip.tripLegs[i].scheduleTime,
            this.timeZone
          ).toISOString();
        }
      }

  }


  ngOnDestroy(): void {}
}
