import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MessageService } from "../../commonService/message.service";
import { AuthService } from "../../service/auth-service/auth.service";
import { Observable, of, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
declare var $: any;
import * as moment from "moment-timezone";
import { Router, NavigationStart, ActivationEnd } from "@angular/router";
// import { SvgIconRegistryService } from "angular-svg-icon";
import { sweetAlert } from "src/app/utils/swal";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  notifictions = [];
  searchword = "";
  todayDate;
  timeZone;
  selectedMenuOption = "dashboard";
  menu = null;
  getState: Observable<any>;
  getUserProfileState: Observable<any>;
  profileDropDown = false;
  notificationDropDown = false;
  smallScreenMenu = true;
  profileImageUrl = null;
  dispalayName = null;
  subscription: Subscription;

  isSearchFilter = false;
  isSearchMember = false;
  isSearchDate = false;

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    // private iconReg: SvgIconRegistryService,
    private socketService: SocketService,
    private sharedDataService: SharedDataService
  ) {
    this.timeZone = this.authService.getUserData()
      ? this.authService.getUserData().timeZone
      : "America/New_York";
  }

  ngOnInit() {
    this.socketService
      .addListener(Events.DASHBOARD_NOTIFICTION)
      .subscribe((data) => {
        if (data.sucess) {
          this.notifictions.push(data);
        }
      });

    // this.iconReg.addSvg(
    //   "dashboard",
    //   '<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 17H2a2 2 0 01-2-2V2C0 .9.9 0 2 0h16a2 2 0 012 2v13a2 2 0 01-2 2h-5l4 2v1H3v-1l4-2zM2 2v11h16V2H2z" /></svg>'
    // );
    // this.iconReg.addSvg(
    //   "planner",
    //   '<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 1l10 6-10 6L0 7l10-6zm6.67 10L20 13l-10 6-10-6 3.33-2L10 15l6.67-4z" /></svg>'
    // );
    // this.iconReg.addSvg(
    //   "import",
    //   '<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9 10V8h2v2h2v2h-2v2H9v-2H7v-2h2zm-5 8h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z" /></svg>'
    // );
    // this.iconReg.addSvg(
    //   "trips",
    //   '<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2 14v-3H1a1 1 0 01-1-1 1 1 0 011-1h1l4-7h8l4 7h1a1 1 0 011 1 1 1 0 01-1 1h-1v6a1 1 0 01-1 1h-1a1 1 0 01-1-1v-1H5v1a1 1 0 01-1 1H3a1 1 0 01-1-1v-3zm13.86-5L13 4H7L4.14 9h11.72zM5.5 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm9 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" /></svg>'
    // );

    // this.iconReg.addSvg(
    //   "staffs",
    //   '<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5 5a5 5 0 0110 0v2A5 5 0 015 7V5zM0 16.68A19.9 19.9 0 0110 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z" /></svg>'
    // );
    // this.iconReg.addSvg(
    //   "logs",
    //   '<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" /></svg>'
    // );
    // this.iconReg.addSvg(
    //   "reports",
    //   '<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>'
    // );

    this.subscription = this.messageService
      .filterObservable()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((message) => {
        // console.log(message);
        if (message) {
          this.isSearchFilter = message.searchFilter;
          this.isSearchMember = message.searchMember;
          this.isSearchDate = message.searchDate;
        } else {
          this.isSearchFilter = false;
          this.isSearchMember = false;
          this.isSearchDate = false;
        }
      });

    // this.todayDate = moment
    //   .tz(new Date(), this.timeZone)
    //   .startOf("day")
    //   .toISOString();

    this.todayDate = moment(new Date()).startOf("day").toISOString();
    // first call
    this.messageService.sendMessage(this.searchword, this.todayDate);

    // this.getUserProfileState
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((state) => {
    //     console.log('ProfileState', state);
    //     if (state.success) {
    //       if (
    //         state.type === UserProfileActionTypes.UPDATE_PROFILE_IMAGE &&
    //         state.errorMessage != null
    //       ) {
    //         this.profileImageUrl = state.response.user.profileImageURL;
    //       }
    //     }
    //   });

    // this.getState
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((state) => {
    //     console.log('AuthState', state);
    //     if (state.user != null) {
    //       this.dispalayName = state.user.displayName;
    //       if (!this.profileImageUrl) {
    //         this.profileImageUrl = state.user.profileImageURL;
    //       }
    //     }
    //   });

    this.sharedDataService
    .getUser()
    .subscribe(user => {
      if(user) {
        this.dispalayName = user.displayName;
        this.profileImageUrl = user.profileImageURL;
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.selectedMenuOption = event.snapshot.url[0]
          ? event.snapshot.url[0].path
          : null;
      }
    });
  }

  sendMessage(): void {
    this.messageService.sendMessage(this.searchword, this.todayDate);
  }

  onDateChange(event) {
    this.todayDate = event.toISOString();
    this.messageService.sendMessage(this.searchword, this.todayDate);
  }

  clearMessage(): void {
    this.messageService.clearMessage();
  }

  menuOptionChanged(type) {
    this.selectedMenuOption = type;
  }

  sortMethod(a, b) {
    return a.value.position > b.value.position ? 1 : -1;
  }

  toggleProfileDropDown() {
    this.profileDropDown = !this.profileDropDown;
  }
  toggleNotificationDropDown() {
    this.notificationDropDown = !this.notificationDropDown;
  }

  toggleSmallScreenMenu() {
    this.smallScreenMenu = !this.smallScreenMenu;
  }

  logout() {
    sweetAlert(
      "Alert",
      "Are you sure you want to logout?",
      "warning",
      "Yes",
      "No"
    ).then((result) => {
      if (result.value) {
        this.socketService.disconnectSocket();
        this.authService.removeToken();
        this.router.navigateByUrl('signIn');
        // this.store.dispatch(new LogOut());
      } else if (result.dismiss) {
        close();
      }
    });
  }

  ngOnDestroy(): void {
    this.socketService.removeListener(Events.DASHBOARD_NOTIFICTION);
  }

  onClickedOutside(e: Event) {
    this.profileDropDown = false;
    this.notificationDropDown = false;
  }
}
