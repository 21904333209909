import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'src/app/commonService/message.service';
import { Observable, from, Subscription } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import {
  addDataTableScript,
  removeDataTableScript,
} from 'src/app/utils/utils.common';
import * as moment from 'moment-timezone';
import {AuthService} from '../../../service/auth-service/auth.service';
import { TripService } from '../trip-service/trip.service';
declare var $;
@Component({
  selector: 'app-trip-list',
  templateUrl: './trip-list.component.html',
  styleUrls: ['./trip-list.component.css'],
})
export class TripListComponent implements OnInit, OnDestroy {
  dataList = [];
  status = 'unassigned';
  search: any;
  selectedDate: any;
  subscription: Subscription;
  scriptLoaded = false;
  timeZone;
  constructor(
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private tripService: TripService,
    private authService: AuthService

  ) {
    this.timeZone = this.authService.getUserData()
      ? this.authService.getUserData().timeZone
      : "America/New_York";
  }

  ngOnInit() {
    // this.selectedDate = moment(new Date()).set({hour: 4, minute: 0, second: 0, millisecond: 0})
    //   .format('YYYY-MM-DDTHH:mm:ss');
    //   this.selectedDate = this.selectedDate + '.000Z';
    // this.selectedDate = moment
    //   .utc(new Date())
    //   .local()
    //   .startOf('day')
    //   .toISOString();

    this.selectedDate = moment().tz(this.timeZone).startOf('day').toISOString();

    console.log(this.selectedDate);
    this.messageService.showFilter({
      searchFilter: true,
      searchMember: false,
      searchDate: true,
    });

    if (this.activatedRoute.snapshot.url.length === 2) {
      this.status = this.activatedRoute.snapshot.url[1].path;
      // console.log(this.status);
    }

    // this.subscription = this.messageService
    //   .getMessage()
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((message) => {
    //     console.log(message);
    //     if (this.search !== message.text) {
    //       this.search = message.text;
    //     }

    //     if (this.selectedDate !== message.date) {
    //       this.selectedDate = message.date;
    //       this.getDataList();
    //     }
    //   });
    this.getDataList();
  }

  onDateChange(event) {
    this.selectedDate = event.toISOString();
    this.getDataList();
  }

  getDataList() {
    // empty array
    this.dataList.length = 0;
    const object = {
      isShowAllTrips: false,
      status: this.status,
      date: this.selectedDate,
    };

    this.tripService
    .getAssignList(object)
    .subscribe(
      data => { 
        if (data) {
          this.dataList = data;
          if (this.scriptLoaded) {
            const table = $('#dataTableExample').DataTable();
            table.clear();
            table.destroy();
            removeDataTableScript();
          }
          addDataTableScript();
          this.scriptLoaded = true; 
        }
      },
      error => {
        sweetAlert('Error', error, 'warning', 'OK');
      }
    );
  }

  ngOnDestroy(): void {
    this.messageService.hideFilter();
  }
}
