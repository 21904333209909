import { Component, OnInit, OnDestroy } from "@angular/core";
import { sweetAlert } from "../../../utils/swal";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";
import { DashboardService } from "../service-dashboard/dashboard.service";
@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.css"],
})
export class OverviewComponent implements OnInit, OnDestroy {
  dashboardList;

  constructor(
    private dasboardService: DashboardService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    this.socketService
      .addListener(Events.DASHBOARD_NOTIFICTION)
      .subscribe((data) => {
        this.getDashboard();
      });

    this.getDashboard();
  }
  getDashboard() {
    this.dasboardService
      .getDashboardStats()
      .subscribe(
        data => {
          this.dashboardList = data;
        },
        error => {
          if(error) {
            sweetAlert("Error", error, "warning", "OK");
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.socketService.removeListener(Events.DASHBOARD_NOTIFICTION);
  }
}
