import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { EditUserProfileComponent } from './component/user profile/edit-user-profile/edit-user-profile.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
import { OverviewComponent } from './component/dashboard/overview/overview.component';
import { AddStaffComponent } from './component/staffs/add-staff/add-staff.component';
import { StaffListComponent } from './component/staffs/staff-list/staff-list.component';
import { CreateTripComponent } from './component/trips/create-trip/create-trip.component';
import { TripListComponent } from './component/trips/trip-list/trip-list.component';
import { ViewTripComponent } from './component/trips/view-trip/view-trip.component';
import { LogsComponent } from './component/trips-logs/logs/logs.component';
import { ReportComponent } from './component/trips-report/report/report.component';
import {MemberReportComponent} from './component/trips-report/member-report/member-report.component';
const routes: Routes = [

  { path: 'signIn', component: SignInComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'profile', component: EditUserProfileComponent, canActivate: [AuthorizationGuard] },

  { path: '', redirectTo: 'dashboard/overview', pathMatch: 'full' },
  { path: 'dashboard/overview', component: OverviewComponent, canActivate: [AuthorizationGuard] },
  
   // trips
   { path: 'trips/form', component: CreateTripComponent, canActivate: [AuthorizationGuard] },
   { path: 'trips/edit', component: CreateTripComponent, canActivate: [AuthorizationGuard] },
   { path: 'trips/view', component: ViewTripComponent, canActivate: [AuthorizationGuard] },
   { path: 'trips/view/:id', component: ViewTripComponent, canActivate: [AuthorizationGuard] },
   
   { path: 'trips', redirectTo: 'trips/outstanding', pathMatch: 'full' },
   { path: 'trips/outstanding', component: TripListComponent, canActivate: [AuthorizationGuard] },
   { path: 'trips/offered', component: TripListComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/accepted', component: TripListComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/inprogress', component: TripListComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/rejected', component: TripListComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/cancelled', component: TripListComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/finished', component: TripListComponent, canActivate: [AuthorizationGuard] },

  //Logs
  { path: 'logs', component: LogsComponent, canActivate: [AuthorizationGuard] },
  
  // staffs
  { path: 'staffs/add', component: AddStaffComponent, canActivate: [AuthorizationGuard] },
  { path: 'staffs/:id/edit', component: AddStaffComponent, canActivate: [AuthorizationGuard] },
  
  { path: 'staffs', redirectTo: 'staffs/list', pathMatch: 'full' },
  { path: 'staffs/list', component: StaffListComponent, canActivate: [AuthorizationGuard] },
  
  
  // reports
  { path: 'reports/trips', component: ReportComponent, canActivate: [AuthorizationGuard] },
  { path: 'reports/members', component: MemberReportComponent, canActivate: [AuthorizationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
