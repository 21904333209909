import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {AuthService} from '../../../service/auth-service/auth.service';
import { sweetAlert } from '../../../utils/swal';
import { ReportService } from '../report-service/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  searchForm: FormGroup;
  submitted = false;
  facilityReportsData;
  selectedFacility = null;
  startDate = null;
  endDate = null;
  totalJobFee = 0;
  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    console.log(this.authService.getUserData());
    this.selectedFacility = this.authService.getUserData();

    this.searchForm = this.fb.group({
      user: [this.authService.getUserData()._id, Validators.required],
      reportType: ['facility', Validators.required],
      providerStatus: [''],
      startDate: [null],
      endDate: [null],
      dateRange: ['']
    });
  }

  onDateChange(event) {
    if (event) {
      this.searchForm.patchValue({
        startDate: event[0].toISOString(),
        endDate: event[1].toISOString()
      });
    }
  }

  get form() {
    return this.searchForm.controls;
  }

  onSubmit() {
    this.facilityReportsData = undefined;
    console.log(this.searchForm);
    this.submitted = true;
    if (this.searchForm.invalid) {
      return;
    }

    this.reportService
    .getFacilityReportsList(this.searchForm.value)
    .subscribe(
      data => {
        this.facilityReportsData = data[0].jobs;
        console.log(this.facilityReportsData);
        this.startDate = this.searchForm.value.startDate;
        this.endDate = this.searchForm.value.endDate;
        this.calculateJobFee(this.facilityReportsData);      
      },
      error => {
        sweetAlert('Error', error, 'warning', 'OK');
      }
    );
  }

  calculateJobFee(trips) {
    this.totalJobFee = 0;
    trips.forEach(element => {
      this.totalJobFee += element.jobFee;
    });
  }

  downloadFacilityReport() {
    if (this.facilityReportsData.length > 0) {
      console.log(this.selectedFacility);

      this.reportService
      .downloadFacilityReport (
        {
          reportData: this.facilityReportsData,
          facility: this.selectedFacility,
          startDate: this.startDate,
          endDate: this.endDate, jobFee: this.totalJobFee,
          reportType: 'Trips Report'
        }
      )
      .subscribe(
        data => {
          if (data) {
            console.log(data);
            this.downloadReport(data);
          }
        },
        error => {
          sweetAlert('Error', error, 'warning', 'OK');
        }
      );
    }
  }

  downloadReport(data) {

          // console.log(url, fileName);
          const body =  document.body as HTMLDivElement;
          const linkSource =  data.pdfFile;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.target = '_blank';
          downloadLink.download = data.fileName;
          body.appendChild(downloadLink);
          console.log(downloadLink);
          console.log(data.pdfFile);
          console.log(data.fileName);
          console.log(downloadLink.click());
          // downloadLink.click();
          // downloadLink.dispatchEvent(new MouseEvent('click'));
          const event = document.createEvent('Event');
          event.initEvent('click', true, true);
          downloadLink.dispatchEvent(event);
          // (window.URL || window.webkitURL).revokeObjectURL(downloadLink.href);

  }

}
