import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private user = new BehaviorSubject(null);

  constructor() { }

  getUser() {
    return this.user;
  }

  saveUser(user: any) {
    this.user.next(user);
  }
}
