import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Form } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageFileProcessing, FileSnippet } from '../../../utils/image-processing-utils';
import { sweetAlert } from '../../../utils/swal';
import { StaffService } from '../staff-service/staff.service';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.css']
})
export class AddStaffComponent implements OnInit, OnDestroy {
  addNewStaff: FormGroup;
  submitted = false;
  btnName = 'Create';
  selectedFile: FileSnippet = null;
  profileImageURL = null;
  userId = null;
  passwordToggleFlag = true;
  successMessage = '';
  errorMessage = '';

  constructor(
    private fb: FormBuilder, 
    private activatedRoute: ActivatedRoute,
    private staffService: StaffService, 
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.addNewStaff = this.fb.group({
      displayName: ['', [Validators.required, Validators.minLength(3)]],
      contactNumber: ['', Validators.required],
      address: ['', Validators.required],
      latitude: [{ value: '', disabled: false }],
      longitude: [{ value: '', disabled: false }],
      // roles: ['faccess', Validators.required],
      accountStatus: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      staffId: [''],
      timeZone: ['America/New_York'],
      images: ['']
    });

    this.fillForm();
  }
  get form() {
    return this.addNewStaff.controls;
  }

  fillForm() {
    if (this.activatedRoute.snapshot.url.length === 3 && this.activatedRoute.snapshot.url[2].path === 'edit') {
      this.userId = this.activatedRoute.snapshot.url[1].path;

      this.staffService
        .getStaffById(this.userId)
        .subscribe(
          data => {
            this.addNewStaff.patchValue({ ...data, password: '123456' });
            this.profileImageURL = data.profileImageURL;
          },
          error => {
            sweetAlert('Error', error, 'error', 'Ok')
            .then(() => {
              this.router.navigateByUrl('staffs');
            });
          }
        );

      this.btnName = 'Update';
    }
  }

  processFile(imageInput: any) {
    imageFileProcessing(imageInput).subscribe((result) => {
      this.selectedFile = result.file;
      this.profileImageURL = result.src;
    });
  }

  getAddress(place) {
    this.addNewStaff.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }


  newStaff() {

    this.submitted = true;
    if (this.addNewStaff.invalid) {
      return;
    } else if (this.selectedFile == null && this.profileImageURL == null) {
      return;
    } else {
      const image = {
        newProfilePicture: this.selectedFile
      };
      if (this.btnName === 'Create') {
        this.addNewStaff.patchValue({
          images: image
        });
        this.addStaff(this.addNewStaff.value);
        this.successMessage = 'Staff Added Successfully';
        this.errorMessage = 'Staff Not Added Successfully';
      } else {
        delete this.addNewStaff.value.password;
        this.addNewStaff.patchValue({
          staffId: this.userId,
          images: image
        });
        this.addStaff(this.addNewStaff.value);
        this.successMessage = 'Staff Updated Successfully';
        this.errorMessage = 'Staff Not Updated Successfully';
      }
    }
  }

  addStaff(staff) {
    this.staffService
      .addStaff(staff)
      .subscribe(
        data => {
          if (data.success) {
            sweetAlert('Success', this.successMessage, 'success', 'Ok')
              .then(() => {
                this.router.navigateByUrl('staffs');
              });
          } else {
            sweetAlert('Error', this.errorMessage, 'error', 'Ok'); 
          }
        },
        error => {
          sweetAlert('Error', error, 'error', 'Ok')
            .then(() => {
              this.router.navigateByUrl('staffs');
            });
        }
      );
  }

  alert(){

  }

  ngOnDestroy(): void {
  }

}
