import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { sweetAlert } from '../../utils/swal';
import { AuthService } from 'src/app/service/auth-service/auth.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onForgotPassword() {
    this.submitted = true; 
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    const payload = {
      email: this.forgotPasswordForm.value.email
    };
    this.authService
      .forgetPassword(payload.email)
      .subscribe(
        data => {
          console.log('Forget Password', data);
          if (data.success) {
            sweetAlert('Succcess', data.message, 'success', 'OK');
          } else {
            sweetAlert('Error', data.message, 'error', 'OK');
          }
        },
        error => {
          sweetAlert('Error', error, 'error', 'OK');
        }
      );
  }

  get form() {
    return this.forgotPasswordForm.controls;
  }
}
