import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AuthService } from '../../service/auth-service/auth.service';
import { Router } from '@angular/router';
import { sweetAlert } from '../../utils/swal';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  platform: any = 'nemt';
  checked: boolean;
  success: boolean;
  passwordToggleFlag = true;
  signInForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private socket : SocketService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    console.log('Hostname: ', window.location.hostname);
    if (window.location.hostname.indexOf('nemtprime') > -1 ) {
      this.platform = 'nemtroutes';
    } else if (window.location.hostname.indexOf('nemtplatform') > -1) {
      this.platform = 'nemt';
    }

    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      roles: [false]
    });
  }

  get form() {
    return this.signInForm.controls;
  }


  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  onSignIn() {
    if (this.signInForm.invalid) {
      this.submitted = true;
      return;
    }

    this.authService
    .login(this.signInForm.value)
    .subscribe(
      data => {
        if(data.success) {
          this.authService.setToken(data.token);
          this.sharedDataService.saveUser(data.user);
          this.socket.connectSocket();
          this.router.navigateByUrl('/');
        } else {
          sweetAlert('Error', data.message, 'warning', 'OK');
        }
      },
      error => {
        sweetAlert('Error', error, 'warning', 'OK');
      }
    );
  }
}
