import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { addDataTableScript, removeDataTableScript } from 'src/app/utils/utils.common';
import { StaffService } from '../staff-service/staff.service';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.css']
})
export class StaffListComponent implements OnInit {
  user;
  getStaffListState: Observable<any>;
  staffList = [];
  search: any;
  imageModalUrl;

  constructor(
    private staffService: StaffService
  ) { }

  ngOnInit() {
    this.getStaff();
  }

  getStaff() {
    this.staffService
    .getStaffList()
    .subscribe(
      data => {
        this.staffList = data;
        addDataTableScript();
      },
      error => {
        sweetAlert('Error', error, 'warning', 'OK');
      }
    );
  }

  selectedUser(myUser) {
    this.user = myUser;
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }
}
