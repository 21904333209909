import { Injectable } from "@angular/core";
import { MainMenuModel } from "./menu-model";
import { SubMenuModel } from "./menu-model";

@Injectable({
  providedIn: "root",
})
export class DynamicMenuService {
  constructor() { }
  menu = {};
  roles = "*";
  company = "company";
  facility = "facility";
  facilitystaff = "faccess";
  admin = "admin";
  addMenuItem(menuObj: MainMenuModel): void {
    if (menuObj.roles.includes(this.roles) || menuObj.roles.includes("*")) {
      menuObj.subMenu = [];
      this.menu[menuObj.state] = menuObj;
    }
  }
  addSubMenuItem(state: string, subMenuObj: SubMenuModel) {
    if (
      (this.menu[state] && subMenuObj.roles.includes(this.roles)) ||
      subMenuObj.roles.includes("*")
    ) {
      this.menu[state].subMenu.push(subMenuObj);
    }
  }
  getMenu(role): object {
    console.log(role);
    this.generateMenu(role);
    return this.menu;
  }
  generateMenu(role) {
    this.roles = role;
    this.addMenuItem({
      url: "/dashboard/overview",
      class: "fa fa-desktop",
      position: 1,
      title: "Dashboard",
      state: "dashboard",
      roles: [this.admin, this.facilitystaff, this.facility],
      subMenu: [],
    });

    // this.addMenuItem({
    //   url: '/planner/trips',
    //   iconPath: 'planner',
    //   position: 2,
    //   title: 'Planner',
    //   state: 'planner',
    //   roles: [this.admin,this.facility],
    //   subMenu: []
    // });
    this.addMenuItem({
      url: "/trips/outstanding",
      class: "fa fa-car",
      position: 3,
      title: "Trips",
      state: "trips",
      roles: [this.admin, this.facilitystaff, this.facility],
      subMenu: [],
    });
    /**
     * Commented Nov 11, 2020
     */
    // this.addMenuItem({
    //   url: "/staffs/list",
    //   class: "fas fa-user",
    //   position: 4,
    //   title: "Staffs",
    //   state: "staffs",
    //   roles: [this.admin, this.facility],
    //   subMenu: [],
    // });

    this.addMenuItem({
      url: "/logs",
      class: "fa fa-file",
      position: 5,
      title: "Logs",
      state: "logs",
      roles: [this.admin, this.facility, this.facilitystaff],
      subMenu: [],
    });

    // this.addMenuItem({
    //   url: "/reports/trips",
    //   class: "fas fa-file-pdf",
    //   position: 6,
    //   title: "Reports",
    //   state: "reports",
    //   roles: [this.admin, this.facility, this.facilitystaff],
    //   subMenu: [],
    // });



    // this.addMenuItem({
    //   url: "/reports/lcp",
    //   iconPath: "reports",
    //   position: 5,
    //   title: "Reports",
    //   state: "reports",
    //   roles: [this.admin,  this.facilitystaff, this.facility],
    //   subMenu: [],
    // });

    // this.addMenuItem({
    //   url: "/import/tripFile",
    //   iconPath: "import",
    //   position: 2,
    //   title: "Import File",
    //   state: "import",
    //   roles: [this.admin, this.facility],
    //   subMenu: [],
    // });


    this.addSubMenuItem("dashboard", {
      title: "Overview",
      url: "/dashboard/overview",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    /// --- trips
    this.addSubMenuItem("trips", {
      title: "Outstanding",
      url: "/trips/outstanding",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    this.addSubMenuItem("trips", {
      title: "Offered",
      url: "/trips/offered",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    this.addSubMenuItem("trips", {
      title: "Accepted",
      url: "/trips/accepted",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    this.addSubMenuItem("trips", {
      title: "Inprogress",
      url: "/trips/inprogress",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    this.addSubMenuItem("trips", {
      title: "Rejected",
      url: "/trips/rejected",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    this.addSubMenuItem("trips", {
      title: "Cancelled",
      url: "/trips/cancelled",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    this.addSubMenuItem("trips", {
      title: "Finished",
      url: "/trips/finished",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    this.addSubMenuItem("trips", {
      title: "Create Trip",
      url: "/trips/form",
      roles: [this.admin, this.facilitystaff, this.facility],
    });

    // this.addSubMenuItem("trips", {
    //   title: "Create Trip",
    //   url: "/trips/form",
    //   roles: [this.admin,this.facilitystaff, this.facility],
    // });

    /// --- staffs
    /**
     * Commented Nov 11, 2020
     */
    // this.addSubMenuItem("staffs", {
    //   title: "List",
    //   url: "/staffs/list",
    //   roles: [this.admin, this.facility],
    // });

    // this.addSubMenuItem("staffs", {
    //   title: "Add",
    //   url: "/staffs/add",
    //   roles: [this.admin, this.facility],
    // });


    // this.addSubMenuItem("import", {
    //   title: "Trip File",
    //   url: "/import/tripFile",
    //   roles: [this.admin, this.facility],
    // });


    // reports
    // this.addSubMenuItem("lcp", {
    //   title: "Lcp",
    //   url: "/reports/lcp",
    //   roles: [this.admin, this.facilitystaff, this.facility],
    // });


    // reports
    // this.addSubMenuItem("reports", {
    //   title: "Trips",
    //   url: "/reports/trips",
    //   roles: [this.admin, this.facilitystaff, this.facility],
    // });

    // // reports
    // this.addSubMenuItem("reports", {
    //   title: "Members",
    //   url: "/reports/members",
    //   roles: [this.admin, this.facilitystaff, this.facility],
    // });

  }
}
