export const dummyData = {
    "facility": "5faa8b8382778b4bc69fd5c7",
    "dob": "2020-11-10T19:00:00.000Z",
    "additionalPersonGender": "",
    "memberHomeZipCode": "123456",
    "driverInstructions": "",
    "memberNotes": "",
    "medicalId": "1234567",
    "payorType": "Medicaid",
    "doctorContactNumber": "+1111111",
    "doctorName": "MR Khan ",
    "tripReason": "Doctor Appointment",
    "prefferedProvider": false,
    "additionalPersonName": "",
    "additionalMember": false,
    "additionalOptions": "singleLeg",
    "isWillCall": false,
    "isOxygen": false,
    "isCarSeat": false,
    "isLongDistance": false,
    "isWheelChair": true,
    "iSschUrgentCare": false,
    "isUrgentCare": true,
    "memberId": "",
    "companyNote": "Patient to be shifted carefully",
    "jobDestinationLatitude": 31.492558,
    "jobDestinationLongitude": 74.3924679,
    "jobDestinationAddress": "Nishat Colony Lahore, Punjab, Pakistan",
    "jobOriginLatitude": 31.5144612,
    "jobOriginLongitude": 74.3445998,
    "jobOriginAddress": "Al Hafeez Heights, 65 Ghalib Rd, Block D1 Block D 1 Gulberg III, Lahore, Punjab, Pakistan",
    "tripRequirement": "WAV",
    "priorityClient": {
        "clientId": "123456",
        "cellPhone": "",
        "contactNumber": "+000000",
        "displayName": "BB Returns"
    },
    "jobPassengerNumber": 1
   
}