export function getCompanyLogo(driverCar): string {

  switch (driverCar) {
    case 'Standard':
      return '../assets/images/vehicles/standardcab.png';

    case 'Premium':
      return '../assets/images/vehicles/premiumcab.png';

    case 'SUV':
      return '../assets/images/vehicles/suv.png';
    case 'WAV':
      return '../assets/images/vehicles/wav.png';
    case 'Stretcher':
      return '../assets/images/vehicles/stretcher.png';
    default:
      return '../assets/images/vehicles/fleet-img.png';
  }
}

export function getBrokerImage(brokerName): string {
  switch (brokerName) {
    case 'united':
      return '../assets/images/brokers/SouthETrans.png';

    case 'lcp':
      return '../assets/images/brokers/lcp-logo.png';

    case 'first':
      return '../assets/images/brokers/mtm-logo.jpg';
    case 'logisticscare':
      return '../assets/images/brokers/logisticare.jpg';
    case 'medtrans':
      return '../assets/images/brokers/medtrans-logo.png';
    case 'medicaid':
      return '../assets/images/brokers/medicaid.png';
    case 'amera':
      return '../assets/images/brokers/amera.png';
    case 'veyo':
      return '../assets/images/brokers/veyo-logo.png';
    case 'vapremier':
      return '../assets/images/brokers/vapremier.png';
    case 'mas':
      return '../assets/images/brokers/mas.png';
    case 'epic':
      return '../assets/images/brokers/epic.png';
    case 'onecall':
      return '../assets/images/brokers/onecall.png';
    default:
      return '../assets/images/brokers/MixandMatch.png';
  }
}

export const getBrokersList = {
  default: 'Default',
  lcp: 'LCP',
  first: 'MTM',
  logisticscare: 'Logisticare',
  medtrans: 'NMN',
  united: 'SouthEastrans',
  medicaid: 'Medicaid',
  amera: 'Amera',
  veyo: 'Veyo',
  vapremier: 'Va Premier',
  mas: 'MAS',
  epic: 'Epic',
  onecall: 'OneCall'
};

export function loadStaticMap(lat, lng) {
  const mapKey = 'AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo';
  const url = 'https://maps.googleapis.com/maps/api/staticmap?center='
    + lat + ',' + lng + '&zoom=14&size=130x150&markers=color:red|' + lat
    + ',' + lng + '&key=' + mapKey;
  return url;
}

export function loadMyScript(url: string) {
  const body =  document.body as HTMLDivElement;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = false;
  script.defer = true;
  body.appendChild(script);
}

// export  function emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
//     var emailRegex = /^[a-zA-Z0-9]+.{0,1}[a-zA-Z0-9]+@[a-z]{3,}\.[a-z]{2,}$/;
//     const email: string = control.value;
//     if (email === null || email === "" || emailRegex.test(email) === true) {
//       return null;
//     }
//     else if (emailRegex.test(email) === false) {
//       return { "domain": true };
//     }
//   }

export function addDataTableScript() {
  loadMyScript('assets/vendors/datatables.net/jquery.dataTables.js');
  loadMyScript('assets/vendors/datatables.net-bs4/dataTables.bootstrap4.js');
  loadMyScript('assets/js/data-table.js');
}

export function removeDataTableScript() {
  $('script[src=\'assets/vendors/datatables.net/jquery.dataTables.js\']').remove();
  $('script[src=\'assets/vendors/datatables.net-bs4/dataTables.bootstrap4.js\']').remove();
  $('script[src=\'assets/js/data-table.js\']').remove();
}


export function roundToTwo(input) {
  return  Math.round(input * 100) / 100;
}
