import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { setFormData } from '../../../utils/network-utils';
import { errorHandl } from '../../../utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  pdfReportURL = 'http://pdfserver.angelridenetwork.com/api/'
  constructor(private http: HttpClient) {
  }

  getFacilityReportsList(data): Observable<any> {
    if (!data.providerStatus) {
      delete data.providerStatus
    }
    if (!data.endDate || !data.startDate) {
      delete data.endDate,
      delete data.startDate
    }
    delete data.dateRange;
    return this.http.post(urls.BASE_URL + "facilitytrips/generatereport", data)
      .pipe(
        catchError(errorHandl)
      );
  }


  downloadFacilityReport(data): Observable<any> {
    console.log(data)
    console.log(this.pdfReportURL)
    return this.http.post(this.pdfReportURL + "report/facilities", data)
    .pipe(
      catchError(errorHandl)
    );
  }

  downloadMemberReport(data): Observable<any> {
    return this.http.post(this.pdfReportURL + "report/member", data)
    .pipe(
      catchError(errorHandl)
    );
  }
}
