import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class MessageService {
  private subject: BehaviorSubject<any> = new BehaviorSubject(null);
  private subjectFilter: BehaviorSubject<any> = new BehaviorSubject(null);

  showFilter(message) {
    this.subjectFilter.next(message);
  }
  filterObservable(): Observable<any> {
    return this.subjectFilter.asObservable();
  }
  hideFilter() {
    this.subjectFilter.next(null);
  }

  sendMessage(message: string, date: string) {
    // console.log('Searched Text',message);
    this.subject.next({ text: message , date: date   });
  }

  clearMessage() {
    this.subject.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
