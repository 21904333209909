import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth-service/auth.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as moment from 'moment-timezone';
import { SocketService } from '../socket-service/socket-service.service';

@Injectable({
    providedIn: 'root'
  })
export class AuthorizationGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private socket: SocketService
    ) {
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        return this.authService.VerifyToken()
            .pipe(
                map((data) => {
                    if (data.user != null) {
                        this.socket.connectSocket();
                        this.authService.CheckLoginValidation(data.user);
                        moment.tz.setDefault(this.authService.getUserData().timezone);
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError(() => {
                    return of(false);
                })
            );
    }
}

