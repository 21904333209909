import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "./service/auth-service/auth.service";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { Observable } from "rxjs";
import * as moment from "moment-timezone";
import { DateTimeAdapter } from "ng-pick-datetime";
import { ToastService } from './toast-messages/toastService/toast.service';
import { SocketService } from './service/socket-service/socket-service.service';
import { Events } from './utils/event-utils';
import { sweetAlert } from './utils/swal';
import { SharedDataService } from "./service/shared-service/shared-data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('audioOption', { static: true }) audioPlayerRef: ElementRef;

  isLogIn = false;
  title = "nemt-angular8";
  state;
  getState: Observable<any>;
  forgotPasswordFlag = false;
  DateTimeAdapter;

  constructor(
    private authService: AuthService,
    private router: Router,
    dateTimeAdapter: DateTimeAdapter<any>,
    private toast: ToastService,
    private socketService: SocketService,
    private sharedDataService: SharedDataService
  ) {
    this.routerListener();
    this.DateTimeAdapter = dateTimeAdapter;
    this.DateTimeAdapter.setLocale("en-US");
  }

  ngOnInit() {


      // const message = "I have some useful information for you..."
      // this.toast.sendMessage(message, 'info')
      // this.toast.sendMessage(message, 'info')
      // this.toast.sendMessage(message, 'info')
      // this.toast.sendMessage(message, 'info')
      // this.toast.sendMessage(message, 'info')
      // this.toast.sendMessage(message, 'info')
      // this.toast.sendMessage(message, 'info')
      // this.onAudioPlay();
      // this.toast.sendMessage('message', 'info')

      this.socketService.addListener(Events.DASHBOARD_NOTIFICTION)
      .subscribe(data => {
        if (data.sucess) {
          this.onAudioPlay();
          this.toast.sendMessage(data.message, 'info')
        } else {
          sweetAlert('Error', data.message, 'warning', 'OK');
        }
      });
    

    
    // this.getState.pipe(takeUntil(componentDestroyed(this))).subscribe(state => {
    //   this.state = state;
    //   console.log('Verify Token State', this.state);
    //   if (state.user === null && state.token === null) {
    //     this.isLogIn = false;
    //     this.authService.CheckLoginValidation(null, this.isLogIn);
    //   } else {
    //     this.isLogIn = true;
    //     if (state.user != null) {
    //       this.authService.CheckLoginValidation(state.user, this.isLogIn);
    //       // moment.tz.setDefault(this.authService.getUserData().timezone);
    //     }
    //   }
    //   // console.log('AppComponent ErrorMessage', state, this.isLogIn);
    // });
    this.verifyToken();
  }

  verifyToken() {
    // if (this.state.user === null && this.state.token !== null) {
      
    // }

    this.authService
      .VerifyToken()
      .subscribe(data => {
        console.log('VerifyToken', data);
        if (!data.user && !this.authService.getToken('token')) {
          this.isLogIn = false;
          this.authService.CheckLoginValidation(null);
        } else {
          this.isLogIn = true;
          if (data.user != null) {
            this.authService.CheckLoginValidation(data.user);
            this.sharedDataService.saveUser(data.user);
          }
        }
      });
    
    // if (this.state.user === null && this.state.token !== null) {
    //   this.store.dispatch(new VerifyToken());
    // }
  }

  routerListener() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        console.log(">>>>>> Navi", event.url);
        if (this.authService.getToken("token")) {
          this.isLogIn = true;
          if (event.url === "/signIn" || event.url === "/forgotPassword") {
            this.router.navigateByUrl("/");
          }
        } else if (this.authService.getToken("token") == null) {
          this.isLogIn = false;
          if (event.url === "/forgotPassword") {
            // this.router.navigateByUrl('forgotPassword');
            this.forgotPasswordFlag = true;
          } else if (event.url !== "/signIn") {
            this.router.navigateByUrl("signIn");
          } else {
            this.forgotPasswordFlag = false;
          }
        } else {
          this.isLogIn = true;
        }
      }
    });
  }
  
   onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }

  ngOnDestroy(): void {
    this.socketService.removeListener(Events.DASHBOARD_NOTIFICTION);
  }
}
