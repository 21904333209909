import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageFileProcessing } from '../../../utils/image-processing-utils';
import { UserProfileService } from '../user-profile-service/user-profile.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.css']
})
export class EditUserProfileComponent implements OnInit, OnDestroy {

  editProfileForm: FormGroup;
  submitted = false;
  selectedProfileImageFile: null;
  userData = null;
  getUserProfileState: Observable<any>;
  imageSubmitted = false;

  profileImageUrl = null;

  tzone = 'America/New_York';
  
  toggleCurrentPassword = true;
  toggleNewPassword = true;
  toggleConfirmPassword = true;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userProfileService: UserProfileService,
    private sahredDataService: SharedDataService
  ) {
    this.tzone = authService.getUserData() ? authService.getUserData().timeZone : 'America/New_York'
  }

  get form() {
    return this.editProfileForm.controls;
  }



  ngOnInit() {
    // this.iconReg.addSvg(
    //   "show-password",
    //   '<svg class="h-5 w-5 text-gray-400 group-hover:text-indigo-400 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20"> <path d="M.2 10a11 11 0 0119.6 0A11 11 0 01.2 10zm9.8 4a4 4 0 100-8 4 4 0 000 8zm0-2a2 2 0 110-4 2 2 0 010 4z" /></svg>'
    //   );
    //   this.iconReg.addSvg(
    //     "hide-password",
    //     '<svg class="h-5 w-5 text-gray-400 group-hover:text-indigo-400 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">  <path   d="M12.81 4.36l-1.77 1.78a4 4 0 00-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0112.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 01-12.62 5.64l1.77-1.78a4 4 0 004.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z" /> </svg>'
        
    //   );

    this.editProfileForm = this.fb.group({
      displayName: [{ value: '', disabled: true }, Validators.required],
      email: [{ value: '', disabled: true }, Validators.required],
      address: [{ value: '', disabled: true }, Validators.required],
      timeZone: [{value:this.tzone,disabled: true}, Validators.required],
      currentPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      verifyPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
    });


    // this.getUserProfileState
    // .pipe(takeUntil(componentDestroyed(this)))
    // .subscribe((state) => {
    //   if (state.success) {
    //     if (state.type === UserProfileActionTypes.UPDATE_USER_PASSWORD) {
    //       sweetAlert('Succcess', state.response.message, 'success', 'OK');
    //       this.afterEditSuccess();
    //     } else if (state.type === UserProfileActionTypes.UPDATE_PROFILE_IMAGE && state.errorMessage != null) {
    //       sweetAlert('Success', "Image Updated Successfully", 'success', 'Ok');
    //       this.authService.CheckLoginValidation(state.response.user, true);
    //       this.afterEditSuccess();
    //       this.profileImageUrl = state.response.user.profileImageURL;
    //     }
    //   } else if (state.errorMessage){

    //     sweetAlert('Error', state.errorMessage, 'warning', 'OK');
    //   }

    // });
    this.userData = this.authService.getUserData();
    this.setUpView();
  }

  afterEditSuccess() {
    this.selectedProfileImageFile = null;
    this.userData = this.authService.getUserData();
    this.editProfileForm.reset();
    this.submitted = false;
    this.setUpView();
  }

  setUpView() {

    if (this.userData != null) {
      this.editProfileForm.patchValue({
        ...this.userData,
        timeZone:this.userData.timeZone
      });
      this.profileImageUrl = this.userData.profileImageURL;
    }
  }

  processProfileFile(ImageInput: any) {
    imageFileProcessing(ImageInput).subscribe((result) => {
      if (result === null) {
        this.selectedProfileImageFile = result;
        this.profileImageUrl = result;
      } else {
        this.selectedProfileImageFile = result.file;
        this.profileImageUrl = result.src;
      }
    });
  }


  onEditProfileSubmit() {
    this.submitted = true;
    if (this.editProfileForm.invalid) {
      return;
    } else {
      console.log(this.editProfileForm)
      // this.store.dispatch(new UpdateUserPassword(this.editProfileForm.value));
      this.userProfileService
      .ChangePasswordRequest(this.editProfileForm.value)
      .subscribe(
        data => {
          sweetAlert('Succcess', data.message, 'success', 'OK');
          this.afterEditSuccess();
        },
        error => {
          if(error) {
            sweetAlert('Error', error, 'warning', 'OK');
          }
        }
      );
    }
  }

  onImageUpload() {
    this.imageSubmitted = true;
    if ((this.selectedProfileImageFile == null && this.profileImageUrl == null)) {
      return;
    }
    // this.store.dispatch(new UploadProfileImage(this.selectedProfileImageFile))
    this.userProfileService
      .changeProfileImage(this.selectedProfileImageFile)
      .subscribe(
        data => {
          sweetAlert('Success', "Image Updated Successfully", 'success', 'Ok');
          this.authService.CheckLoginValidation(data.user);
          this.afterEditSuccess();
          this.profileImageUrl = data.user.profileImageURL;
          this.sahredDataService.saveUser(data.user);
        },
        error => {
          if (error) {
            sweetAlert('Error', error, 'warning', 'OK');
          }
        }
      );
  }

  clearImage() {
    this.selectedProfileImageFile = null;
    this.profileImageUrl = this.userData.profileImageURL;
  }

  
  togglePassword(fieldName: string) {
    switch (fieldName) {
      case 'current':
        this.toggleCurrentPassword = !this.toggleCurrentPassword;
        break;
      case 'new':
          this.toggleNewPassword = !this.toggleNewPassword;
          break;
      case 'confirm':
          this.toggleConfirmPassword = !this.toggleConfirmPassword;
          break;
    }
  }
  ngOnDestroy(): void {
  }

  getProfileType() {

    if(this.userData){
     return  this.userData.roles[0] === 'facility'? 'Facility' : 'Staff';
    }else{
      return '';
    }

  }
}
